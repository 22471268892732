import $ from 'jquery';
import slick from 'slick-carousel';
import isRtl from '../utils/is-rtl';
export default () => {
  $('.js-hb-slider').slick({
    autoplay: true,
    autoplaySpeed: 4000,
    rtl: isRtl(),
    dots: true,
    arrows: false,
  });
};
