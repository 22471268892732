import $ from 'jquery';
export default function hideOnClickOutside(selector,hideClass) {
    const outsideClickListener = (event) => {
      if (!$(event.target).closest(selector).length) {
        if ($(selector).is(':visible')) {
          
            if($(selector).hasClass('filters-dropdown')){
                $(selector).find($('.filters-dropdown-list')).css({
                    'max-height': 0
                  });
            }
          $(selector).removeClass(hideClass);
        }
      }
    }
    document.addEventListener('click', outsideClickListener)
  }