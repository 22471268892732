import $ from 'jquery';

export default () => {
    const loadMore = $('.food-load-more-otg');
    const itemsPerPage = loadMore.find('.items-per-page');
    const pageNumber = loadMore.find('.page-number');
    const sortExpression = loadMore.find('.sort-expression').val();
    const loadMoreContainer = $('.load-more-container');
    const isRtl = $('html').attr('dir') === 'rtl';
    const foodsContainer = $('#food-data');
    const loadMoreText = $('.load-more-container .load-more-text');
    const apiUrl = $('#food-api').val();

    loadMore.click((e) => {
        e.preventDefault();
        if (!isRtl) {
            loadMoreText.text('LOADING ...');
        } else {
            loadMoreText.text('جاري التحميل ...');
        }

        var take = parseInt(itemsPerPage.val());
        var skip = take * parseInt(pageNumber.val());
        var culture = isRtl ? 'ar' : 'en';
        $.get(apiUrl, { skip, take, sortExpression, culture }, (data) => {
            data.Foods.forEach((food) => {
                let foodHtml = $('#food-on-the-go-template').html();
                foodHtml = foodHtml.replace(/\[\[MediaUrl\]\]/g, food.MediaUrl)
                    .replace(/\[\[MediaAlternativeText\]\]/g, food.MediaAlternativeText)
                    .replace(/\[\[MediaTitle\]\]/g, food.MediaTitle)
                    .replace(/\[\[Title\]\]/g, food.Title)
                    .replace(/\[\[Description\]\]/g, food.Description);

                foodsContainer.append(foodHtml);
            });

            const totNumCards = foodsContainer.find('[data-food]').length;
            if (data.Count <= totNumCards) {
                loadMoreContainer.hide();
            }
            if (!isRtl) {
                loadMoreText.text('LOAD MORE');
            } else {
                loadMoreText.text('عرض المزيد');
            }
            var currentPageNumber = parseInt(pageNumber.val());
            currentPageNumber++;
            pageNumber.val(currentPageNumber);
        });
    });
};
