import $ from 'jquery';

export default () => {
  // Attach scroll event to window. Calculate the scroll ratio of each element
  // and change the image position with that ratio.
  // https://codepen.io/lemagus/pen/RWxEYz
  $(window).on('scroll resize', function () {
    let scrolled = $(window).scrollTop();
    $('.js-to-animate').each(function (index, element) {
      const initY = $(this).offset().top;
      const height = $(this).height();
      const endY = initY + $(this).height();

      // Check if the element is in the viewport.
      let visible = isInViewport(this);
      if (visible) {

        let diff = scrolled - initY;
        let ratio = Math.round((diff / height) * 100);
        if ($(this).hasClass('bg-element-bot')) {
          $(this).css('bottom', parseInt(-(ratio / 5)) + 'px')
        } else {

          $(this).css('top', parseInt(-(ratio / 5)) + 'px')
        }
      }
    });
  })

  // Check if the element is in the viewport.
  // http://www.hnldesign.nl/work/code/check-if-element-is-visible/
  function isInViewport(node) {
    // Am I visible? Height and Width are not explicitly necessary in visibility
    // detection, the bottom, right, top and left are the essential checks. If an
    // image is 0x0, it is technically not visible, so it should not be marked as
    // such. That is why either width or height have to be > 0.
    var rect = node.getBoundingClientRect()
    return (
      (rect.height > 0 || rect.width > 0) &&
      rect.bottom >= 0 &&
      rect.right >= 0 &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }
}
