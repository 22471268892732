import $ from 'jquery';

// accepts two params, the select element  currently used, and his mobile/desktop version
const changeSelectValue = (select, selectToChange) => {

  const selectData = select.data('select');
  const selectVal = select.val();
  

  $.each(selectToChange, function () {
      const $this = $(this);
      const selectToChangeData = $this.data('select');
      const selectToChangeParent = $this.parents('.js-filters');
      
      if (selectData === selectToChangeData) {
      $this.val(selectVal).trigger('change');

      selectToChangeParent.addClass('is-selected');
    }
  });
}

// accepts two params, the select element  currently used, and his mobile/desktop version
const checkSelectsValueForEqual = (selectVal, selectToCheck) => {
  let isEqual = false;
  $.each(selectToCheck, function () {
    const $this = $(this);
    const selectToChangeVal = $this.val();

    if (selectVal === selectToChangeVal) {
      isEqual = true;

    }
  });
  return isEqual;
}
export default {
  checkSelectsValueForEqual,
  changeSelectValue
}
