import $ from 'jquery';

export default () => {
    const loadMore = $('.js-load-more-otg');
    const itemsPerPage = $('.js-load-more-otg .items-per-page');
    const pageNumber = $('.js-load-more-otg .page-number');
    const sortExpression = $('.js-load-more-otg .sort-expression').val();
    const loadMoreContainer = $('.load-more-container');
    const isRtl = $('html').attr('dir') === 'rtl';
    const blogsContainer = $('#blogs-data');
    const loadMoreText = $('.load-more-container .load-more-text');

    loadMore.click((e) => {
        e.preventDefault();
        if (!isRtl) {
            loadMoreText.text('LOADING ...');
        } else {
            loadMoreText.text('جاري التحميل ...');
        }

        var take = parseInt(itemsPerPage.val());
        var skip = take * parseInt(pageNumber.val());
        const culture = isRtl ? 'ar' : 'en';
        $.get('/api/blogs', { skip, take, sortExpression, culture }, (data) => {
            data.Blogs.forEach((blog) => {
                let blogHtml = $('#blog-template').html();
                blogHtml = blogHtml.replace(/\[\[MediaUrl\]\]/g, blog.MediaUrl)
                    .replace(/\[\[MediaAlternativeText\]\]/g, blog.MediaAlternativeText)
                    .replace(/\[\[MediaTitle\]\]/g, blog.MediaTitle)
                    .replace(/\[\[Title\]\]/g, blog.Title)
                    .replace(/\[\[ReadMoreLink\]\]/g, blog.ReadMoreBtn)
                    .replace(/\[\[ShortDescription\]\]/g, blog.ShortDescription);


                blogsContainer.append(blogHtml);
            });

            const totNumCards = blogsContainer.find('[data-blog]').length;
            if (data.NumCards <= totNumCards) {
                loadMoreContainer.hide();
            }
            if (!isRtl) {
                loadMoreText.text('LOAD MORE');
            } else {
                loadMoreText.text('عرض المزيد');
            }
            var currentPageNumber = parseInt(pageNumber.val());
            currentPageNumber++;
            pageNumber.val(currentPageNumber);
        });
    });
};
