import $ from 'jquery';
import slick from 'slick-carousel';
import isRtl from '../utils/is-rtl';

export default () => {
  const lightboxTrigger = $('.js-show-lightbox');
  const lighboxEl = $('.js-lightbox');

  lightboxTrigger.on('click', function () {
    const slideIndex = $(this).parents('.slick-slide').index();
    const parentSlider = $(this).parents('.js-slider-sided').data('lightbox');
    $.each(lighboxEl, function () {
      const sliderData = $(this).data('slider');
      if (parentSlider == sliderData) {
        $(this).data('activeSlide', slideIndex);
        $(this).modal('show');
      }
    });
  });

  lighboxEl.on('shown.bs.modal', function () {
    const activeSlideIndex = $(this).data('activeSlide');

    $('.lightbox-slider').slick({
      rtl: isRtl(),
      arrows: true,
      prevArrow: $('.js-lightbox-arrow-prev'),
      nextArrow: $('.js-lightbox-arrow-next'),
      adaptiveHeight: true,
      infinite: true,
      slidesToshow: 1,
      initialSlide: activeSlideIndex,
      fade: true,
      cssEase: 'linear'
    });
  });
  lighboxEl.on('hidden.bs.modal', function (e) {
    const lighboxSlider = $(this).find('.lightbox-slider');
    lighboxSlider.slick('unslick');
  });
};
