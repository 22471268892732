import $ from 'jquery';

const headerNav = () => {
  const mobileButton = $('.js-sandwitch');
  const header = $('.header');
  const subNavBtn = $('.js-submenu-button');
  const subNavParent = subNavBtn.parents('.header-nav-mobile-item-submenu');
  const subNav = subNavParent.find('.header-mobile-submenu');
  const subMenu = $('.header-nav-item-submenu');
  const headerNavEl = $('.header-nav');
  let disableDesktopMenu = false;
  let headerHeight = null;
  const stickOffset = 5;
  subMenu.mouseenter(() => {
    $('body').addClass('bg');
  });
  subMenu.mouseleave(() => {
    $('body').removeClass('bg');
  });

  mobileButton.click(() => {
    headerHeight = window.screen.availHeight - $('.header-top-container').height();
    if ($('html').hasClass('block-scroll')) {
      $('html, body').removeClass('block-scroll');
    } else {

      $('html, body').addClass('block-scroll');
    }
    header.toggleClass('is-active');

  });

  subNavBtn.click((event) => {
    const $this = $(event.currentTarget);
    const subNavParent = $this.parents('.header-nav-mobile-item-submenu');
    const subNav = subNavParent.find('.header-mobile-submenu');
    if (!$this.hasClass('is-active')) {
      subNav.slideDown(150, () => {
        subNavParent.addClass('is-active');
        $this.addClass('is-active');
      }).css({
        display: 'flex'
      });
    } else {
      subNav.slideUp(150, () => {
        $this.removeClass('is-active');
        subNavParent.removeClass('is-active');
      });
    }
  });
 

  const checkScroll = () => {
    let windowTop = $(window).scrollTop();

    if ($('.footer').length) {
      if ($('.footer').offset().top - $('.header-nav').innerWidth() <= windowTop) {
          headerNavEl.css({ position: 'absolute', top: $('.footer').offset().top - $('.header-nav').innerWidth() + 'px' });
      } else  {
          headerNavEl.css({ position: 'fixed', top: stickOffset });
      }
    }
  };
  const checkWindow = () => {
    
    if ($(window).outerHeight() > 655 || $(window).outerHeight() > $(window).outerWidth()) {
      checkScroll();
      disableDesktopMenu = false;
    } else {
      disableDesktopMenu = true;
    }
    if (disableDesktopMenu === false) {
      if ($(window).outerWidth() > 768 || $(window).outerHeight() < $(window).outerWidth()) {
        checkScroll();
        disableDesktopMenu = false;
      } else {
        disableDesktopMenu = true;

      }
    }

  }
  $(window).scroll(() => {
    checkWindow();

  });
  $(window).resize(() => {
    checkWindow();
    headerHeight = window.screen.availHeight - $('.header-top-container').height();
  });
  checkWindow();
}

export default () => {
  headerNav();

};
