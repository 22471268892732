import $ from 'jquery';
import slick from 'slick-carousel';
import isRtl from '../utils/is-rtl';
export default () => {
  $('.js-awards-slider').slick({
    rtl: isRtl(),
    infinite: false,
    variableWidth: true,
    dots: false,
    arrows: false,
    responsive: [
        {
          breakpoint: 992,
          settings: {

            dots: true
          }
        },
    ]
  });
};
