import $ from 'jquery';
import hideOnClickOutside from './outside';
import 'bootstrap/js/dist/modal';
import helperSelect from './selectHelpers';

const isRtl = $('html').attr('dir') === 'rtl';
const apiUrl = $('#recipes-api').val();
const loadMoreContainer = $('.load-more-container');
const itemsPerPage = loadMoreContainer.find('.items-per-page');
const pageNumber = loadMoreContainer.find('.page-number');
const sortExpression = loadMoreContainer.find('.sort-expression').val();
const loadMoreText = loadMoreContainer.find('.load-more-text');

const appendCards = (section, clearPreviousCards) => {
  const checkedFilter = $('#recipe-filters input[name="filtersRadioInline1"]:checked').val();

  if (!isRtl) {
    loadMoreText.text('LOADING ...');
  } else {
    loadMoreText.text('جاري التحميل ...');
  }
  if (clearPreviousCards) {
    section.find('[data-cardcol]').remove();
    pageNumber.val(0);
  }

  let take = parseInt(itemsPerPage.val());
  let skip = take * parseInt(pageNumber.val());
  let culture = isRtl ? 'ar' : 'en';
  return $.get(apiUrl, {
    checkedFilter,
    skip,
    take,
    sortExpression,
    culture
  }).done((data) => {
    const cardsContainer = section.find('[data-cards-container]');

      data.Recipes.forEach((recipe) => {
      let recipeHtml = $(`#cards-item-template`).html();
          recipeHtml = recipeHtml.replace(/\[\[mediaUrl\]\]/g, recipe.MediaUrl)
              .replace(/\[\[mediaAlternativeText\]\]/g, recipe.MediaAlternativeText)
              .replace(/\[\[mediaTitle\]\]/g, recipe.MediaTitle)
              .replace(/\[\[title\]\]/g, recipe.Title)
              .replace(/\[\[text\]\]/g, recipe.ShortDescription)
              .replace(/\[\[preparationTime\]\]/g, recipe.PreparationTime)
              .replace(/\[\[calorieCount\]\]/g, recipe.CalorieCount)
              .replace(/\[\[category\]\]/g, recipe.Category)
              .replace(/\[\[ingredientsCount\]\]/g, recipe.IngredientsCount)
              .replace(/\[\[navigateUrl\]\]/g, recipe.NavigateUrl);

          cardsContainer.append(recipeHtml);
    });

    const totNumCards = section.find('[data-cardcol]').length;
      if (data.Count <= totNumCards) {
          loadMoreContainer.hide();
      }
      else {
     if (loadMoreContainer.hasClass('d-none')) {
       loadMoreContainer.removeClass('d-none');
    }
          loadMoreContainer.show();
      }
      if (!isRtl) {
          loadMoreText.text('LOAD MORE');
      } else {
          loadMoreText.text('عرض المزيد');
      }
      let currentPageNumber = parseInt(pageNumber.val());
      currentPageNumber++;
      pageNumber.val(currentPageNumber);
  });
};

const recreateCards = (section) => {
  const sectionHeight = Math.ceil(section.height());
  section.addClass('loading');
  section.find('.media-center-loadmore-row').removeClass('active');

  // delay for the CSS animation
  setTimeout(() => {

    appendCards(section, true)
      .always(() => {
        // delay for the CSS animation
        setTimeout(() => {
          section.removeClass('loading');
        }, 200);
      });
  }, 200);
};

export default () => {

  let recipeFilter = $('.with-filters').find('[data-recipe-filter]');
  if (recipeFilter) {
    let recipeFilterTemplate = $('#recipe-filter-template');
    recipeFilter.append(recipeFilterTemplate.html());
    recipeFilterTemplate.remove();
  }

  // if ($('.filters-dropdown').length) {
  hideOnClickOutside($('.filters-dropdown'), 'is-active');

  // }
  const hideDropdown = (section) => {
    if (section.hasClass('is-active')) {
      section.removeClass('is-active');
      section.find($('.filters-dropdown-list')).css({
        'max-height': 0
      });
    }
  }
  const checkMultipleFilters = (filters, sectionData, checkbox) => {
    $.each(filters, function () {
      const section = $(this);
      const $thisData = $(this).data('filter');
      if ($thisData === sectionData.filter) {
        const checkboxDropdown = $(this).find('.js-filters-control');

        hideDropdown(section);

        $.each(checkboxDropdown, function () {
          const $this = $(this);
          if ($this.val() === checkbox.val()) {
            section.find('.filters-radio').removeClass('is-active');
            $this.parents('.filters-radio').addClass('is-active');

          }
        });
      }
    });
  }
  $('.js-filters-control').on('change', (event) => {
    if ($(window).width() <= 767) {
      return;
    }
    const checkbox = $(event.currentTarget);
    const selectVal = checkbox.val();
    const section = checkbox.closest('.js-filters');
    const sectionData = section.data();
    checkMultipleFilters($('.js-filters'), sectionData, checkbox);

    let equalVal = helperSelect.checkSelectsValueForEqual(selectVal, $('.js-radio-m'));

    if (!equalVal) {
      helperSelect.changeSelectValue(checkbox, $('.js-radio-m'));
    }
    if (checkbox.is(':checked')) {
      section.find('.filters-radio').removeClass('is-active');
      checkbox.parents('.filters-radio').addClass('is-active');

    }
    recreateCards($('.cards'));
  });
  $('.js-radio-m').on('change', function () {
    if ($(window).width() > 768) {
      return;
    }

    const $this = $(this);
    const selectVal = $this.val();
    const selectParent = $this.parents('.js-filters');
    let equalVal = false;
    selectParent.addClass('is-selected');

    $.each($('.js-filters-control'), function () {
      const checkbox = $(this);
      const section = checkbox.closest('.js-filters');
      if (checkbox.val() === selectVal) {
        checkbox.val(selectVal);
        checkbox.prop('checked', true);
        section.find('.filters-radio').removeClass('is-active');
        checkbox.parents('.filters-radio').addClass('is-active');
      }
    });
    recreateCards($('.cards'));
  });
  $('.js-load-more').click((event) => {
    const section = $('.cards');

    appendCards(section, false);
  });
  $(window).on('resize', () => {
    if ($(window).width() > 768) {
      $('.js-custom-modal').modal('hide');
    }
  })
};
