import $ from 'jquery';
import slick from 'slick-carousel';
import isRtl from '../utils/is-rtl';
export default () => {
  $.each($('.js-s-details'), function () {
    let sliderParent = $(this).parent();
    $(this).slick({
      rtl: isRtl(),
      infinite: true,
      arrows: true,
      prevArrow: sliderParent.find('.js-r-slider-arrows-prev'),
      nextArrow: sliderParent.find('.js-r-slider-arrows-next')
    });
  });
  $('.js-slider-details-mobile').slick({
    rtl: isRtl(),
    infinite: false,
    variableWidth: true,
    dots: false,
    arrows: false
  });
};
