import $ from 'jquery';

export default () => {
    const loadMore = $('.js-offers-load-more-otg');
    const itemsPerPage = loadMore.find('.items-per-page');
    const pageNumber = loadMore.find('.page-number');
    const sortExpression = loadMore.find('.sort-expression').val();
    const loadMoreContainer = $('.load-more-container');
    const isRtl = $('html').attr('dir') === 'rtl';
    const offersContainer = $('#offers-data');
    const loadMoreText = $('.load-more-container .load-more-text');
    const apiUrl = $('#offers-api').val();

    loadMore.click((e) => {
        e.preventDefault();
        if (!isRtl) {
            loadMoreText.text('LOADING ...');
        } else {
            loadMoreText.text('جاري التحميل ...');
        }

        var take = parseInt(itemsPerPage.val());
        var skip = take * parseInt(pageNumber.val());
        const culture = isRtl ? 'ar' : 'en';
        $.get(apiUrl, { skip, take, sortExpression, culture }, (data) => {
            data.Offers.forEach((offer) => {
                let offerHtml = $('#offers-template').html();
                offerHtml = offerHtml.replace(/\[\[MediaUrl\]\]/g, offer.MediaUrl)
                    .replace(/\[\[MediaAlternativeText\]\]/g, offer.MediaAlternativeText)
                    .replace(/\[\[MediaTitle\]\]/g, offer.MediaTitle)
                    .replace(/\[\[Title\]\]/g, offer.Title)
                    .replace(/\[\[NavigateUrl\]\]/g, offer.NavigateUrl)
                    .replace(/\[\[Description\]\]/g, offer.Description);

                offersContainer.append(offerHtml);
            });

            const totNumCards = offersContainer.find('[data-offers]').length;
            if (data.Count <= totNumCards) {
                loadMoreContainer.hide();
            }
            if (!isRtl) {
                loadMoreText.text('LOAD MORE');
            } else {
                loadMoreText.text('عرض المزيد');
            }
            var currentPageNumber = parseInt(pageNumber.val());
            currentPageNumber++;
            pageNumber.val(currentPageNumber);
        });
    });
};
