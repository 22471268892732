import $ from 'jquery';
import slick from 'slick-carousel';
import isRtl from '../utils/is-rtl';
export default () => {
    $('.js-slider-sided').slick({
    rtl: isRtl(),
    infinite: false,
    variableWidth: true,
    dots:true,
    arrows:false
    });
    // function reSiz() {
    //   var wd = (($('.js-slider-sided').width() / 1000) * 290);
   
  
    //   $('.slider-sided-slide.last').css({
    //     'width': wd,
    //   });
  
    //   $('.slider-sided-slide').not('.last').css({
    //     'width': wd,
    //     'padding-right': 3/100 * wd,
    //   });
  
    //   $('.js-slider-sided').slick('setPosition');
    // }
  
    // reSiz();
  
    // $(window).resize(function(){
    //   reSiz();
    // });
  };