/**
 * jquery quick validation
 * http://www.prototype.ae
 */

import jQuery from 'jquery';

(function main($) {
  const ValidateEmail = s => /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z]{2,4})+$/.test(s);
  const ValidatePhone = s => /^(?:\+971|00971|0)?(?:50|51|52|54|55|56|57|58|59)\d{7}$/.test(s);
  const ValidateText = s => /.*[^ ].*/.test(s);
  const ValidateName = s => /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/.test(s);
  // eslint-disable-next-line no-param-reassign
  $.fn.validate = function validate(options, self) {
    // settings
    const settings = $.extend({
      required: 'Field is required',
      email: 'Please enter a valid email',
      phone: 'Please enter a valid phone',
      name: 'Please enter a valid name',
      helpClass: 'error-inline',
      ignoreInput: () => false
    }, options);

    let clean = true;
    let globalClean = true;
    const $this = this || self;

    $this.find('select, input, textarea').each((fieldIndex, field) => {
      clean = true;
      const ths = $(field);
      const val = ths.val();
      const parent = ths.parent();

      if (settings.ignoreInput(ths)) {
        return;
      }

      let help = parent.find(` > .${settings.helpClass}`);
      if (help.length === 0) {
        parent.append(`<div class='${settings.helpClass}'></div>`);
        help = parent.find(` > .${settings.helpClass}`);
      }

      const isRequired = ths.data('required') || ths.attr('required');
      const isEmail = (ths.data('validate') === 'email' || ths.data('email-message'))
        && ths.attr('type') === 'email';
      const isPhone = ths.data('validate') === 'phone' || ths.data('phone-message');
      const isName = ths.attr('placeholder') === 'name' && ths.data('text-message');
      const isText = ths.data('validate') === 'text' || ths.data('text-message');
      if (isRequired && val === '') {
        clean = false;
        help.show().html(ths.data('required-message') || settings.required);
      }
      if (val !== '' && isEmail && !ValidateEmail(val)) {
        clean = false;
        help.show().html(ths.data('email-message') || settings.email);
      }
      if (val !== '' && isPhone && !ValidatePhone(val)) {
        clean = false;
        help.show().html(ths.data('phone-message') || settings.phone);
      }
      if (val !== '' && isText && !ValidateText(val)) {
        clean = false;
        help.show().html(ths.data('text-message') || settings.name);
      }
      if (val !== '' && isName && !ValidateName(val)) {
        clean = false;
        help.show().html(ths.data('text-message') || settings.name);
      }
      if (clean) {
        help.hide();
        parent.removeClass('error');
      } else {
        globalClean = false;
        parent.addClass('error');
        ths.one('blur', (blurEvent) => {
          $(blurEvent.currentTarget).parent().validate(null, self);
        });
      }
    });

    return globalClean;
  };
}(jQuery));
