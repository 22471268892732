import $ from 'jquery';


const focusfirstErrorField = (form) => {
  const errorFields = form.find('[data-form-container-wrapper].error [data-form-container-input]');
  if (!errorFields.length) {
    return;
  }

  const firstErrorField = errorFields.first();
  firstErrorField.focus();

  // eslint-disable-next-line no-undef
  const isDevice = $(window).width() < 992;
  const firstErrorFieldTop = firstErrorField.offset().top;
  const scrollPosition = isDevice ? firstErrorFieldTop - 103 : firstErrorFieldTop - 156;
  // eslint-disable-next-line no-undef
  window.scroll({ top: scrollPosition, left: 0, behavior: 'smooth' });
};

export default () => {
  const contactForm = $('#contact-us-form');
  const loadingImg = contactForm.find('[data-sf-role="loading-img"]');
  const submitUrl = contactForm.find('input[data-sf-role="ajax-submit-url"]').val();
  contactForm.submit((event) => {
    event.preventDefault();
    const form = $(event.currentTarget);

    if (form.validate(null, form)) {
      loadingImg.show();
      // eslint-disable-next-line no-undef
      const formData = new FormData(contactForm[0]);
      const request = new XMLHttpRequest();
      request.open('POST', submitUrl);
      request.onload = function () {
        if (request.status === 200) {
          const responseJson = JSON.parse(request.response);
          if (responseJson.success) {
            $('.contact-us-modal').modal();
            $(':input', '#contact-us-form')
              .not(':button, :submit, :reset, :hidden', ':select')
              .val('')
              .prop('checked', false);
            loadingImg.hide();
            // eslint-disable-next-line no-empty
          } else {}
        }
      };
      request.send(formData);
    } else {
      // Form Validation Failed
      focusfirstErrorField(form);
    }
  });

  $('#modalOkBtn').on('click', () => {
    window.location.href = '/';
  });
};
