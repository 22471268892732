import $ from 'jquery';

export default () => {
  const loadMore = $('.js-load-more-products-otg');
  const itemsPerPage = loadMore.find('.items-per-page');
  const pageNumber = loadMore.find('.page-number');
  const sortExpression = loadMore.find('.sort-expression').val();
  const loadMoreContainer = $('.load-more-container');
  const isRtl = $('html').attr('dir') === 'rtl';
  const productContainer = $('#products-data');
  const loadMoreText = $('.load-more-container .load-more-text');
  const apiUrl = $('#product-url').val();

  loadMore.click((e) => {
    e.preventDefault();
    if (!isRtl) {
      loadMoreText.text('LOADING ...');
    } else {
      loadMoreText.text('جاري التحميل ...');
    }

    const take = parseInt(itemsPerPage.val());
    const skip = take * parseInt(pageNumber.val());
    const culture = isRtl ? 'ar' : 'en';
    $.get(apiUrl, {
      skip, take, sortExpression, culture
    }, (data) => {
      data.Products.forEach((product) => {
        let productHtml = $('#products-template').html();
        productHtml = productHtml.replace(/\[\[MediaUrl\]\]/g, product.MediaUrl)
          .replace(/\[\[MediaAlternativeText\]\]/g, product.MediaAlternativeText)
          .replace(/\[\[MediaTitle\]\]/g, product.MediaTitle)
          .replace(/\[\[Title\]\]/g, product.Title)
          .replace(/\[\[Description\]\]/g, product.Description)
          .replace(/\[\[InstashopUrl\]\]/g, product.InstashopUrl);


        productContainer.append(productHtml);
      });

      const totNumCards = productContainer.find('[data-product]').length;
      if (data.Count <= totNumCards) {
        loadMoreContainer.hide();
      }
      if (!isRtl) {
        loadMoreText.text('LOAD MORE');
      } else {
        loadMoreText.text('عرض المزيد');
      }

      let currentPageNumber = parseInt(pageNumber.val());
      currentPageNumber++;
      pageNumber.val(currentPageNumber);
    });
  });
};
