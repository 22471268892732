import $ from 'jquery';
import slick from 'slick-carousel';
import isRtl from '../utils/is-rtl';
export default () => {
  $('.js-products-slider').slick({
    rtl: isRtl(),
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    variableWidth: true,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
};
