import $ from 'jquery';

const isRtl = $('html').attr('dir') === 'rtl';
const apiUrl = $('#stories-api').val();
const loadMoreContainer = $('.load-more-container');
const itemsPerPage = loadMoreContainer.find('.items-per-page');
const pageNumber = loadMoreContainer.find('.page-number');
const sortExpression = loadMoreContainer.find('.sort-expression').val();
const loadMoreText = loadMoreContainer.find('.load-more-text');

const appendCards = (section, clearPreviousCards) => {
    let pickDateFilter = $('#story-filters select[name="pick-date"] option:selected').val();
    let typeFilter = $('#story-filters select[name="type"] option:selected').val();

    if (!isRtl) {
        loadMoreText.text('LOADING ...');
    } else {
        loadMoreText.text('جاري التحميل ...');
    }
    if (clearPreviousCards) {
        section.find('[data-cardcol]').remove();
        pageNumber.val(0);
    }

    let take = parseInt(itemsPerPage.val());
    let skip = take * parseInt(pageNumber.val());
    const culture = isRtl ? 'ar' : 'en';
    return $.get(apiUrl, {
        pickDateFilter,
        typeFilter,
        skip,
        take,
        sortExpression,
        culture
    }).done((data) => {
        const cardsContainer = section.find('[data-cards-container]');

        data.Stories.forEach((story) => {
            let storyHtml = $(`#stories-item-template`).html();
            storyHtml = storyHtml.replace(/\[\[MediaUrl\]\]/g, story.MediaUrl)
                .replace(/\[\[MediaAlternativeText\]\]/g, story.MediaAlternativeText)
                .replace(/\[\[MediaTitle\]\]/g, story.MediaTitle)
                .replace(/\[\[Category\]\]/g, story.Category)
                .replace(/\[\[Date\]\]/g, story.Date)
                .replace(/\[\[Title\]\]/g, story.Title)
                .replace(/\[\[Content\]\]/g, story.ShortDescription)
                .replace(/\[\[NavigateUrl\]\]/g, story.NavigateUrl);

            cardsContainer.append(storyHtml);
        });

        const totNumCards = section.find('[data-cardcol]').length;
        if (data.Count <= totNumCards) {
            loadMoreContainer.hide();
        }
        else {
            if (loadMoreContainer.hasClass('d-none')) {
                loadMoreContainer.removeClass('d-none');
            }

            loadMoreContainer.show();
        }
        if (!isRtl) {
            loadMoreText.text('LOAD MORE');
        } else {
            loadMoreText.text('عرض المزيد');
        }
        let currentPageNumber = parseInt(pageNumber.val());
        currentPageNumber++;
        pageNumber.val(currentPageNumber);

    });
};

const recreateCards = (section) => {
    section.addClass('loading');

    // delay for the CSS animation
    setTimeout(() => {

        appendCards(section, true)
            .always(() => {
                // delay for the CSS animation
                setTimeout(() => {
                    section.removeClass('loading');
                }, 200);
            });
    }, 200);
};

export default () => {
    $('#story-filters .js-stories-control').on('change', (event) => {
        if ($(window).width() <= 768) {
            return;
        }

        recreateCards($('.cards'));
    });

    $('.js-select2-m').on('change', function () {
        if ($(window).width() > 768) {
            return;
        }

        recreateCards($('.cards'));
    });
    $('.js-load-more-stories').click((event) => {
        const section = $('.cards');

        appendCards(section, false);
    });
};
